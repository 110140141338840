/* global
 autosize
 bsCustomFileInput
 checkRedirect
 initHtmlTextarea
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);
      }
    }
    if ($data.toaster) {
      $body.toaster("updateToaster", $data.toaster);
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

const datatable_checkbox_set = new Set();

$body.on("click", ".custom-control-input[name=RMA]:not(#RMA_select_all)", function () {
  if (this.checked) {
    datatable_checkbox_set.add(this.value);
  } else {
    datatable_checkbox_set.delete(this.value);
  }
  if (datatable_checkbox_set.size === 0) {
    $("#id_rma_link").attr("href", "/rma/assign/");
  } else {
    $("#id_rma_link").attr("href", "/rma/assign/" + Array.from(datatable_checkbox_set).join(",") + "/");
  }
  // toggel_activation_of_start_btn();
});

$body.on("click", ".custom-control-input[name=RMA]#RMA_select_all", function () {
  datatable_checkbox_set.clear();
  if (this.checked) {
    const length = $(".custom-control-input[name=RMA]:not(#RMA_select_all)").length;
    datatable_checkbox_set.add([...Array(length).fill(0).map((e, i) => i + 1)]);
    $("#id_rma_link").attr("href", "/rma/assign/" + Array.from(datatable_checkbox_set).join(",") + "/");
  } else {
    $("#id_rma_link").attr("href", "/rma/assign/");
  }
});

$body.on("submit", ".multi-select-form", function () {
  const selected_rows = $("input[name='selected_rows']");
  if (!selected_rows.length) {
    $("<input />").attr(
      "type",
      "hidden"
    ).attr(
      "name",
      "selected_rows"
    ).attr(
      "value",
      JSON.stringify([...datatable_checkbox_set])
    ).appendTo($(this));
  }
  selected_rows.val(JSON.stringify([...datatable_checkbox_set]));
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

// re-enable all during modal-open disabled data-modal-links
function removeDisabledAfterModalOpened () {
  document.querySelectorAll("[data-modal-link][data-modal-link-disabled]").forEach(function ($el) {
    $el.classList.remove("disabled");
    $el.removeAttribute("data-modal-link-disabled");
  });
}

$x.beforeModalOpenDefault = function ($modal, $data, $el) {
  removeDisabledAfterModalOpened();

  if ($data.submit === "error") {
    if ($data.toaster) {
      $("body").toaster("updateToaster", $data.toaster);
    }
  }
};

$x.onModalOpenDefault = function ($modal) {
  const select2_elms = $(".select2-ajax", $modal);
  if (select2_elms.length > 0) {
    select2_ajax(select2_elms, $modal);
  }
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.onModalCloseDefault = function ($modal, $data, $el) {
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  // set all data-modal-links (which are not already disabled) disabled -> so only one modal can be opened at a time
  document.querySelectorAll("[data-modal-link]:not(.disabled):not([disabled])").forEach(function ($el) {
    $el.setAttribute("data-modal-link-disabled", "data-modal-link-disabled");
    $el.classList.add("disabled"); // only allow data-modal-link to be clicked once until modal opened
  });

  $x.modal.open(
    this.href,
    {
      beforeModalOpen: $x.beforeModalOpenDefault,
      onModalOpen: $x.onModalOpenDefault,
      onModalClose: $x.onModalCloseDefault,
    },
    this
  );
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});


function select2_ajax (elms, parent) {
  parent = typeof parent !== "undefined" ? parent : null;
  elms.each(function () {
    $(this).select2({
      ajax: {
        url: $(this).data("url"),
        delay: 250,
        dataType: "json",
        data: function (params) {
          return {
            search: params.term,
            page: params.page,
          };
        },
        results: function (data) {
          return {
            results: data,
          };
        },
        processResults: function (data) {
          return {
            results: data.results,
          };
        },
      },
      dropdownParent: parent,
      placeholder: "Search...",
      minimumInputLength: 3,
      width: "100%",
      tags: true,
      createTag: function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true,
        };
      },
      templateResult: function (data) {
        const $result = $("<span></span>");

        $result.text(data.text);

        if (data.newOption) {
          $result.append(" <em>(new)</em>");
        }

        return $result;
      },
    });
  });
}


// #############################################################################
// EDIT RMA

/**
 * deleteAppendix(element, undo)
 * to delete an appendix at edit rma this function hidde the image and shows an undo btn.
 * and add the file name in a hidden btn value to delete that file.
 * if undo is true the file name get removed from the hidden value btn and the image will be shown again.
 * @param {object} element
 * @param {boolean} undo
 */
function deleteAppendix (element, undo = false) {
  const undo_icon = element.parent().children(".delete-appendix-undo");
  const delete_icon = element.parent().children(".delete-appendix");
  const img = element.parent().children("a");
  const hidden_input = $("#id_editrmaform-delete_appendix");
  const file_name = element.data("delete-file");

  if (undo) {
    undo_icon.addClass("d-none");
    delete_icon.removeClass("d-none");
    img.removeClass("d-none");
    const value = hidden_input.val().replace("§" + file_name, "");
    hidden_input.val(value);
  } else {
    undo_icon.removeClass("d-none");
    delete_icon.addClass("d-none");
    img.addClass("d-none");
    const value = hidden_input.val();
    hidden_input.val(value + "§" + file_name);
  }
}

// #############################################################################
// TOP BTN

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction () {
  const top_btn = document.getElementById("top-btn");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    top_btn.style.display = "block";
  } else {
    top_btn.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction () {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

/**
 * getPrice() calculate the price of the license from the chosen contingents
 */
function getPrice () {
  const licenceContingent = $("#order-license-type");
  const addonContingent = $("#order-add-on-type");
  const outputText = $("#order-price");
  let licencePrice = "0.0";
  let addonPrice = "0.0";

  if (licenceContingent.length > 0) {
    licencePrice = $("#order-license-type").find(":selected").data("contingent-price");
  }
  if (addonContingent.length > 0) {
    addonPrice = $("#order-add-on-type").find(":selected").data("contingent-price");
  }
  if (outputText.length > 0) {
    if (licencePrice && addonPrice) {
      outputText.html((parseFloat(licencePrice) + parseFloat(addonPrice)).toString());
    } else if (licencePrice) {
      outputText.html((parseFloat(licencePrice)));
    } else if (addonPrice) {
      outputText.html((parseFloat(addonPrice)));
    }
  }
}

getPrice();

// #############################################################################
// EVENT LISTENERS
$(function () {
  const body = $("body");

  body.on("click", ".delete-appendix", function () {
    deleteAppendix($(this));
  });

  body.on("click", ".delete-appendix-undo", function () {
    deleteAppendix($(this), true);
  });

  body.on("click", "#top-btn", topFunction);

  body.on("click", "#jump-to-report-id", function () {
    const year = $("#year-selector").val();
    const month = $("#year-month-selector").val();

    if (year === "-1" && month === "-1") {
      window.location.href = $("#report-url").val();
    } else if (year !== "-1" && month === "-1") {
      window.location.href = $("#report-url").val() + "#" + year;
    } else if (year !== "-1" && month !== "-1") {
      window.location.href = $("#report-url").val() + "#" + year + "-" + month;
    }
  });

  // Add License: When btn "add & order" clicked change to_order to checked
  body.on("click", "#add-and-order", function () {
    $("#id_addlicenseform-to_order").val("True");
    const form = $("[data-form='addlicenseform']");
    form.submit();
  });

  body.on("change", "#order-license-type, #order-add-on-type", getPrice);
});
